<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="12" xl="3">
        <label>Bot Name</label>
      </b-col>
      <b-col cols="12" xl="9">
        <b-form-input
            size="sm"
            v-model="formItem.botName"
            trim
            required
            @change="emitChanges"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col cols="12" lg="3">Skype Username</b-col>
      <b-col>
        <b-input
            ref="inputSkypeUserName"
            size="sm"
            type="email"
            v-model="formItem.botSkypeConfig.skypeUserName"
            required
            placeholder="user@email.com"
            @change="emitIsEditingUsername"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3">Skype User Password</b-col>
      <b-col>
        <b-input
            size="sm"
            type="password"
            v-model="formItem.botSkypeConfig.skypePassword"
            required
            @change="emitChanges"
        />
      </b-col>
    </b-row>
    <!--    <b-row class="mb-2">-->
    <!--      <b-col cols="12" lg="3">Skype User ID</b-col>-->
    <!--      <b-col>-->
    <!--        <b-input-->
    <!--            size="sm"-->
    <!--            v-model="formItem.botSkypeConfig.skypeUserId"-->
    <!--            required-->
    <!--            placeholder="live:.cid.1a1111111aa11111"-->
    <!--            @change="emitChanges"-->
    <!--        />-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <!--    <b-row class="mb-2">-->
    <!--      <b-col cols="12" lg="3">Skype Display Name</b-col>-->
    <!--      <b-col>-->
    <!--        <b-input-->
    <!--            size="sm"-->
    <!--            v-model="formItem.botSkypeConfig.skypeDisplayName"-->
    <!--            required-->
    <!--            placeholder="Skype Bot"-->
    <!--            @change="emitChanges"-->
    <!--        />-->
    <!--      </b-col>-->
    <!--    </b-row>-->

    <!--    <b-row class="mb-2">-->
    <!--      <b-col cols="12" lg="3">Market</b-col>-->
    <!--      <b-col>-->
    <!--        <b-input-->
    <!--            size="sm"-->
    <!--            v-model="formItem.botSkypeConfig.market"-->
    <!--            required-->
    <!--            placeholder="M1"-->
    <!--            @change="emitChanges"-->
    <!--        />-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <b-row class="mb-2">
      <b-col cols="12" lg="3">Default Affiliate Code</b-col>
      <b-col>
        <b-input
            size="sm"
            v-model="formItem.botSkypeConfig.defaultAffiliateCode"
            required
            placeholder="B00001"
            @change="emitChanges"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3"><span>Empty Request Message</span>
        <b-icon ref="i-empty-message" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                variant="info"></b-icon>
        <b-tooltip :target="() => $refs['i-empty-message']"
                   title="Message to display when someone tag the Bot but didn't write anything."></b-tooltip>
      </b-col>
      <b-col>
        <b-input
            size="sm"
            v-model="formItem.botSkypeConfig.messageEmptyRequest"
            required
            placeholder="Please write something."
            @change="emitChanges"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3"><span>File Downloaded Message</span>
        <b-icon ref="i-file-downloaded-message" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                variant="info"></b-icon>
        <b-tooltip :target="() => $refs['i-file-downloaded-message']"
                   title="Message to display when someone uploaded a file to the group."></b-tooltip>
      </b-col>
      <b-col>
        <b-input
            size="sm"
            v-model="formItem.botSkypeConfig.messageFileDownloaded"
            required
            placeholder="File received."
            @change="emitChanges"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3"><span>Provide Information Message</span>
        <b-icon ref="i-provide-information-message" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                variant="info"></b-icon>
        <b-tooltip :target="() => $refs['i-provide-information-message']"
                   title="Message to display when prompting user for information."></b-tooltip>
      </b-col>
      <b-col>
        <b-input
            size="sm"
            v-model="formItem.botSkypeConfig.messageProvideInformation"
            required
            placeholder="Please provide information for these."
            @change="emitChanges"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3"><span>Skype Group Not Found Message</span>
        <b-icon ref="i-group-not-found-message" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                variant="info"></b-icon>
        <b-tooltip :target="() => $refs['i-group-not-found-message']"
                   title="Message to display when the skype group name was not found in Skype Groups list."></b-tooltip>
      </b-col>
      <b-col>
        <b-input
            size="sm"
            v-model="formItem.botSkypeConfig.messageSkypeGroupNotFound"
            required
            placeholder="Invalid Group."
            @change="emitChanges"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" lg="3"><span>Affiliate Code Invalid Message</span>
        <b-icon ref="i-affiliate-code-invalid-message" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                variant="info"></b-icon>
        <b-tooltip :target="() => $refs['i-affiliate-code-invalid-message']"
                   title="Message to display when member does not belongs to the affiliate codes for the Skype Group."></b-tooltip>
      </b-col>
      <b-col>
        <b-input
            size="sm"
            v-model="formItem.botSkypeConfig.messageInvalidAffiliateCode"
            required
            placeholder="Invalid Affiliate Code."
            @change="emitChanges"
        />
      </b-col>
    </b-row>

    <section v-if="!formItem.botId">
      <hr>

      <h6>These values will be used to generate the initial webhooks and intents.</h6>
      <br>
      <b-row class="mb-2">
        <b-col cols="12" xl="3">
          <label>Bot Language</label>
        </b-col>
        <b-col cols="12" xl="9">
          <b-form-select
              size="sm"
              v-model="formItem.botLanguage"
              :options="languages"
              @change="emitChanges"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12" lg="3"><span>CS Skype Name (ID)</span>
          <b-icon ref="i-cs-username" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                  variant="info"></b-icon>
          <b-tooltip :target="() => $refs['i-cs-username']"
                     title="Bot will tag this user using this skype id (skype name)."></b-tooltip>
        </b-col>
        <b-col>
          <b-input
              size="sm"
              v-model="formItem.botSkypeConfig.csSkypeUserName"
              required
              placeholder="live:abcdefg"
              @change="emitChanges"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12" lg="3"><span>CS Skype Username</span>
          <b-icon ref="i-cs-username" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                  variant="info"></b-icon>
          <b-tooltip :target="() => $refs['i-cs-username']"
                     title="The first part of the CS Skype username."></b-tooltip>
        </b-col>
        <b-col>
          <b-input
              size="sm"
              v-model="formItem.botSkypeConfig.csSkypeTagName"
              required
              placeholder="CS"
              @change="emitChanges"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12" lg="3"><span>Brand</span>
          <b-icon ref="i-brand" class="float-right" icon="exclamation-circle-fill" shift-v="-6"
                  variant="info"></b-icon>
          <b-tooltip :target="() => $refs['i-brand']"
                     title="Brand Code to be used for API call."></b-tooltip>
        </b-col>
        <b-col>
          <b-input
              size="sm"
              v-model="formItem.botSkypeConfig.brand"
              required
              placeholder="f1"
              @change="emitChanges"
          />
        </b-col>
      </b-row>
    </section>

    <hr>

    <b-row class="mb-3">
      <b-col cols="12" xl="3">
        <label>Skype Groups</label>
      </b-col>
      <b-col>
        <skype-group-handler v-model="skypeGroups" @input="skypeGroupChanged"/>
      </b-col>
    </b-row>
    <section>
          <span
              class="btn-advance-settings"
              @click="toggleAdvanceSettings = !toggleAdvanceSettings"
          >
            <b-icon-caret-right-fill/> Advance Settings
          </span>
      <b-collapse v-model="toggleAdvanceSettings">
        <b-row class="my-2">
          <b-col cols="12">
            <custom-values v-model="formItem.customValues"/>
          </b-col>
        </b-row>
      </b-collapse>
    </section>
  </div>
</template>

<script>
import CustomValues from "@/components/Intents/CustomValues.vue";
import SkypeGroupHandler from "@/components/Bots/SkypeGroupHandler.vue";
import _ from "lodash";

export default {
  name: 'SkypeBotForm',
  components: {SkypeGroupHandler, CustomValues},
  props: {
    value: Object,
    skypeAccountIsInUse: Boolean,
  },
  mounted() {
    this.setValue();
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.setValue();
      }
    },
    skypeAccountIsInUse() {
      if (this.skypeAccountIsInUse)
        this.$refs['inputSkypeUserName'].setCustomValidity('This account already in use by the system.')
      else
        this.$refs['inputSkypeUserName'].setCustomValidity('')
    },
    'formItem.botSkypeConfig.messageInvalidAffiliateCode'(newValue) {
      if (newValue) {
        let item = _.find(this.formItem.customValues, {key: _invalidAffiliateCodeMessageKey});
        item.value = newValue;
      }
    },
    'formItem.botSkypeConfig.defaultAffiliateCode'(newValue) {
      if (newValue) {
        let item = _.find(this.formItem.customValues, {key: _defaultAffiliateCodeKey});
        item.value = newValue;
      }
    },
    'formItem.botLanguage'(newValue) {
      if (newValue) {
        this.assignLanguageCode(newValue);
      }
    }
  },
  data() {
    return {
      languages: [
        {value: 'English', text: 'English'},
        {value: 'Chinese', text: 'Chinese'},
        {value: 'Vietnamese', text: 'Vietnamese'},
        {value: 'Thai', text: 'Thai'},
      ],
      toggleAdvanceSettings: false,

      skypeGroups: null,
      formItem: {
        "botName": "",
        "botLanguage": "English",
        "customValues": [],
        "botType": 2,
        "botSkypeConfig": {
          "skypeUserName": "",
          "skypePassword": "",
          "skypeUserId": "",
          "skypeDisplayName": "",
          "csSkypeUserName": "",
          "csSkypeTagName": "",
          "brand": "",
          "market": "",
          "defaultAffiliateCode": "",
          "messageEmptyRequest": "",
          "messageFileDownloaded": "",
          "messageProvideInformation": "",
          "messageSkypeGroupNotFound": "",
          "messageInvalidAffiliateCode": "",
        }
      },
    }
  },
  methods: {
    skypeGroupChanged(group) {
      const customValues = [...this.formItem.customValues];
      const index = customValues.findIndex((v) => {
        return v.key === _skypeGroupKey;
      });
      if (index >= 0) {
        if (group) {
          customValues.splice(index, 1, {
            ...{key: _skypeGroupKey},
            ...group,
          });
        } else {
          customValues.splice(index, 1);
        }
      } else {
        if (group) {
          customValues.push({
            ...{key: _skypeGroupKey},
            ...group,
          });
        }
      }
      this.formItem.customValues = customValues;
      this.emitChanges();
    },
    setValue() {
      const formItem = {...this.value};

      if (_.some(formItem.customValues, {key: _skypeGroupKey,})) {
        this.skypeGroups = _.find(formItem.customValues, {key: _skypeGroupKey,});
      } else {
        this.skypeGroups = {key: _skypeGroupKey, value: '{}',};
        formItem.customValues.push(this.skypeGroups);
      }

      this.formItem = formItem;
    },
    emitIsEditingUsername(username) {
      this.$emit('isEditingUsername', username);
      this.emitChanges();
    },
    emitChanges() {
      this.$emit('input', this.formItem);
    },
    assignLanguageCode(language) {
      if (language === 'English') this.formItem.botSkypeConfig.market = 'en'
      else if (language === 'Chinese') this.formItem.botSkypeConfig.market = 'zh'
      else if (language === 'Vietnamese') this.formItem.botSkypeConfig.market = 'vi'
      else if (language === 'Thai') this.formItem.botSkypeConfig.market = 'th'
      else this.formItem.botSkypeConfig.market = 'en';
    }
  }
}

const _skypeGroupKey = 'group_name_affiliate_code';
const _defaultAffiliateCodeKey = 'skype_default_affiliate_code';
const _invalidAffiliateCodeMessageKey = 'affiliate_code_invalid_message';
</script>


<style scoped>

</style>